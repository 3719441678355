const PT_BR = {
  title: "Escola de Programação e Robótica",
  description: "Onde a teoria e a prática se encontram"
}

const PT_PT = {
  title: "Escola de Programação e Robótica",
  description: "Onde a teoria e a prática se encontram"
}

export const header = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
