// prettier-ignore
export const partners = [
  {
    "link": "https://pt-br.facebook.com/chapeuzinhoescola",
    "title": "Chapeuzinho Vermelho",
    "image": "chapeu.png"
  },

  {
    "link": "https://www.facebook.com/BambiniiEscola",
    "title": "Escola Bambini",
    "image": "bambini.jpg"
  },

  {
    "link": "https://www.melhorescola.com.br/escola/castelo-escola",
    "title": "Castelo Escola",
    "image": "castelo-escola.png"
  },

  {
    "link": "https://www.conhecaceinet.com.br",
    "title": "CEI Romualdo Galvão",
    "image": "cei.png"
  },

  {
    "link": "https://www.conhecaceinet.com.br",
    "title": "CEI Roberto Freire",
    "image": "cei.png"
  },

  {
    "link": "https://maplebear.com.br",
    "title": "Maple Bear",
    "image": "maple-bear.png"
  },

  {
    "link": "https://pt-br.facebook.com/newgenerationkids.com.br/",
    "title": "New Generation",
    "image": "new-generation.png"
  },

  {
    "link": "https://www.facebook.com/cic.serratalhada/",
    "title": "Imaculada Conceição",
    "image": "imaculada-conceicao.png"
  },

  {
    "link": "https://www.facebook.com/cenic.brejinho/",
    "title": "CENIC",
    "image": "cenic.jpg"
  },

  {
    "link": "https://www.facebook.com/escolatiathais/",
    "title": "Hotel Escola Infantil Tia Thai",
    "image": "thais.jpg"
  },

  {
    "link": "https://www.facebook.com/escolainfante/",
    "title": "Infante",
    "image": "infante.jpg"
  },

  {
    "link": "https://celminho.com.br",
    "title": "Celminho",
    "image": "celminho.png"
  },

  {
    "link": "https://www.facebook.com/eienatal/",
    "title": "Espaço Integrado de Educação",
    "image": "integrado.jpg"
  },

  {
    "link": "https://www.colegiomariejost.org.br",
    "title": "Colégio Marie Jost",
    "image": "marie-jost.png"
  },

  {
    "link": "https://www.escolasereaprender.com.br",
    "title": "Ser & Aprender",
    "image": "ser-aprender.png"
  },

  {
    "link": "https://www.instagram.com/centroeducacionalsaber/?hl=pt",
    "title": "Centro Educaional do Saber",
    "image": "ces.jpg"
  },

  {
    "link": "https://carlrogers.com.br",
    "title": "Escola Carl Rogers",
    "image": "carl.png"
  },
]
