// prettier-ignore
const PT_BR = {
  title: "Serviços",
  description: "Oferecemos diversos serviços para melhor atendê-los.",
  courses: [
    {
      image: "infantil.jpg",
      title: "Nível Infatil",
      description: "3 aos 5 anos de idade",
      content: {
        schedule: 'Segunda a Sábado',
        place: 'Sede da Roboeduc e Escolas Parceiras',
        text: "No nível Robô i, o foco é a INTERAÇÃO da criança com o próximo e com o seu robô. Com as atividades e metodologia RoboEduc iremos exercitar a orientação espacial, coordenação motora - com ênfase nas habilidades motoras finas -  raciocínio lógico, respeito mútuo, cooperação e colaboração. A criança aprenderá as primeiras noções da programação com base na função controlar e ensinar, dando comandos introdutórios ao seu robô, terá ainda suas  primeiras noções de montagem de forma lúdica e divertida."
      }
    },
    {
      image: "fundamental1.jpg",
      title: "Nível fundamental I",
      description: "6 aos 11 anos de idade",
      content: {
        schedule: "Segunda a Sábado",
        place: "Sede da Roboeduc e Escolas Parceiras",
        text: "No nível Robô - F1, FUNÇÕES DE COMPLEXIDADE 1 já aparecem! O foco é a montagem de protótipos e programação com funções de nível 1(F1). Neste nível os alunos irão realizar programação em blocos, desenvolver robôs com a utilização de manuais de montagem e kits de robótica compostos de bases móveis, manipuladores, sensores ultrassônicos, sensores de toque e de cor, trabalhando com engrenagens, eixos, vigas, etc."
      }
    },
    {
      image: "fundamental2.jpg",
      title: "Nível fundamental II",
      description: "11 aos 15 anos de idade",
      content: {
        schedule: "Segunda a Sábado",
        place: "Sede da Roboeduc e Escolas Parceiras",
        text: "No nível Robô-F2, FUNÇÕES DE COMPLEXIDADE 2. O foco é a montagem de protótipos e programação com funções de nível 2(F2).  Com a utilização de manuais de montagem e montagem livre com kits de robótica educacional o aluno aprenderá conceitos intermediários de robótica, que versam sobre bases fixas e móveis (rodas, esteiras), Sensores (cor, toque e ultrassônico), atuadores e manipuladores, aplicações da robótica (medicina, NASA) e Programação em bloco agora com um nível de complexidade mais elevada, nível F2! "
      }
    },
    {
      image: "medio.jpg",
      title: "Nível Médio",
      description: "15 aos 18 anos de idade",
      content: {
        schedule: "Segunda a Sábado",
        place: "Sede da Roboeduc e Escolas Parceiras",
        text: " No nível Robô-M, é aluno se torna um Maker! Neste nível o aluno conseguirá construir todas as partes de seu robô: projetando, imprimindo peças 3D, desenvolvendo circuitos e programando seu robô. Neste nível a metodologia RoboEduc irá favorecer o desenvolvimento da curiosidade e do senso crítico do aluno. Buscamos criar condições necessárias para o enfrentamento de situações problemas da vida cotidiana, aliando estas ao conteúdo de robótica. Instigando os alunos a proporem soluções, provocando o desenvolvimento e a percepção da interdependência entre as situações problemas e a robótica, de modo a permitir a realização de projetos comuns. "
      }
    },
    {
      image: "gamer.jpg",
      title: "Escola Gamer",
      description: "A partir do 9 anos",
      content: {
        schedule: null,
        place: "Sede Roboeduc",
        text: "Já pensou em criar o seu próprio game? Agora é possível e este curso foi elaborado para você, fã de game que deseja deixar de ser um simples jogador para se tornar desenvolvedor."
      }
    },
    {
      image: "youtube.jpg",
      title: "Escola Youtuber",
      description: "A partir do 9 anos",
      content: {
        schedule: null,
        place: "Sede Roboeduc",
        text: "No decorrer do curso, focaremos na aprendizagem de edição de vídeos com ferramentas profissionais e edições com a própria plataforma do Youtube, noções de fotografia e filmagem, iluminação do ambiente, cenário de apresentação, direitos autorais, estratégias de divulgação do canal, análise de gráfico de público e muito mais."
      }
    },
    {
      image: "capacitacao.jpg",
      title: "Capacitação Docente",
      description: "Treinamento em Robótica Educacional",
      content: {
        schedule: null,
        place: null,
        text: "Oferecemos cursos de capacitação docente, apoio técnico e pedagógico e oficinas aplicadas, para auxiliar os docentes a entenderem um pouco mais sobre a área de Robotica Educacional e se qualificarem para trabalharem de forma mais ativa os conteúdos escolares."
      }
    },
    {
      image: "escola.jpg",
      title: "Roboeduc na Escola",
      description: "Diretor, leve a roboeduc para sua escola",
      content: {
        schedule: null,
        place: null,
        text: "Além das turmas que são ofertadas em nossa sede, também oferecemos nossos serviços para escolas da rede particular e pública de ensino. Entre em contato conosco para saber como nossa metodologia pode ser trabalhada em sua escola!"
      }
    },
    {
      image: "aprendizagem.jpg",
      title: "Objetos Virtuais de Aprendizagem",
      description: "Personalização de conteúdos para ESCOLA",
      content: {
        schedule: null,
        place: null,
        text: "Trabalhamos desenvolvendo Games, Histórias Interativas, Conteúdo em Realizada Virtual e Aumentada, Vídeos Educacionais e Conteúdo EAD para as ESCOLAS."
      }
    },
    {
      image: "educacional.jpg",
      title: "Consultoria em Tecnologias Educacionais",
      description: "Desenvolvimento e acompanhamento de projetos escolares",
      content: {
        schedule: null,
        place: null,
        text: "Consultoria na implantação, desenvolvimento e acompanhamento de projetos que envolvam novas tecnologias em educação para as ESCOLAS."
      }
    },
    {
      image: "sistema.jpg",
      title: "Sistema de Acompanhamento",
      description: "Sistema Web/Mobile de acompanhamento",
      content: {
        schedule: null,
        place: null,
        text: "A Roboeduc conta com um sistema web que possibilita todo o acompamento das atividades realizadas pelos alunos, pais e Gestores da Escola. Ela provê ferramentas de acesso para os pais poderem acompanhar o desenvolvimento de seu filho nas aulas. A mesma ferramenta pode ser utilizada pelos diretores de escolas para melhor gerenciar a aulas, professores, presenças, emitir relatórios e canal de comunicação com os pais. "
      }
    },
    {
      image: "inbox.jpg",
      title: "Roboeduc Inbox",
      description: "EaD de Robótica Educacional",
      content: {
        schedule: null,
        place: null,
        text: "Primeiro EaD de Robótica Educacional do Brasil"
      }
    }
  ]
}

// prettier-ignore
const PT_PT = {
  title: "Serviços",
  description: "A nossa oferta formativa",
  courses: [
    {
      image: "infantil.jpg",
      title: "Ensino Pré-escolar",
      description: "3 - 5 anos",
      content: {
        schedule: 'Segunda a Sábado',
        place: 'Sede da Roboeduc e Escolas Parceiras',
        text: "No nível Robô i, o foco é a INTERAÇÃO da criança com o próximo e com o seu robô. Com as atividades e metodologia RoboEduc iremos exercitar a orientação espacial, coordenação motora - com ênfase nas habilidades motoras finas -  raciocínio lógico, respeito mútuo, cooperação e colaboração. A criança aprenderá as primeiras noções da programação com base na função controlar e ensinar, dando comandos introdutórios ao seu robô, terá ainda suas  primeiras noções de montagem de forma lúdica e divertida."
      }
    },
    {
      image: "fundamental1.jpg",
      title: " 1.º ciclo do Ensino Básico",
      description: "6 - 11 anos",
      content: {
        schedule: "Segunda a Sábado",
        place: "Sede da Roboeduc e Escolas Parceiras",
        text: "No nível Robô - F1, FUNÇÕES DE COMPLEXIDADE 1 já aparecem! O foco é a montagem de protótipos e programação com funções de nível 1(F1). Neste nível os alunos irão realizar programação em blocos, desenvolver robôs com a utilização de manuais de montagem e kits de robótica compostos de bases móveis, manipuladores, sensores ultrassônicos, sensores de toque e de cor, trabalhando com engrenagens, eixos, vigas, etc."
      }
    },
    {
      image: "fundamental2.jpg",
      title: "2.º e 3.º ciclos do Ensino Básico",
      description: "11 - 15 anos",
      content: {
        schedule: "Segunda a Sábado",
        place: "Sede da Roboeduc e Escolas Parceiras",
        text: "No nível Robô-F2, FUNÇÕES DE COMPLEXIDADE 2. O foco é a montagem de protótipos e programação com funções de nível 2(F2).  Com a utilização de manuais de montagem e montagem livre com kits de robótica educacional o aluno aprenderá conceitos intermediários de robótica, que versam sobre bases fixas e móveis (rodas, esteiras), Sensores (cor, toque e ultrassônico), atuadores e manipuladores, aplicações da robótica (medicina, NASA) e Programação em bloco agora com um nível de complexidade mais elevada, nível F2! "
      }
    },
    {
      image: "medio.jpg",
      title: "Ensino Secundário",
      description: "15 - 18 anos",
      content: {
        schedule: "Segunda a Sábado",
        place: "Sede da Roboeduc e Escolas Parceiras",
        text: " No nível Robô-M, é aluno se torna um Maker! Neste nível o aluno conseguirá construir todas as partes de seu robô: projetando, imprimindo peças 3D, desenvolvendo circuitos e programando seu robô. Neste nível a metodologia RoboEduc irá favorecer o desenvolvimento da curiosidade e do senso crítico do aluno. Buscamos criar condições necessárias para o enfrentamento de situações problemas da vida cotidiana, aliando estas ao conteúdo de robótica. Instigando os alunos a proporem soluções, provocando o desenvolvimento e a percepção da interdependência entre as situações problemas e a robótica, de modo a permitir a realização de projetos comuns. "
      }
    },
    {
      image: "gamer.jpg",
      title: "Escola Gamer",
      description: "A partir do 9 anos",
      content: {
        schedule: null,
        place: "Sede Roboeduc",
        text: "Já pensou em criar o seu próprio game? Agora é possível e este curso foi elaborado para você, fã de game que deseja deixar de ser um simples jogador para se tornar desenvolvedor."
      }
    },
    {
      image: "youtube.jpg",
      title: "Escola Youtuber",
      description: "A partir do 9 anos",
      content: {
        schedule: null,
        place: "Sede Roboeduc",
        text: "No decorrer do curso, focaremos na aprendizagem de edição de vídeos com ferramentas profissionais e edições com a própria plataforma do Youtube, noções de fotografia e filmagem, iluminação do ambiente, cenário de apresentação, direitos autorais, estratégias de divulgação do canal, análise de gráfico de público e muito mais."
      }
    },
    {
      image: "capacitacao.jpg",
      title: "Formação Docente",
      description: "Treino em Robótica Educacional",
      content: {
        schedule: null,
        place: null,
        text: "Oferecemos cursos de capacitação docente, apoio técnico e pedagógico e oficinas aplicadas, para auxiliar os docentes a entenderem um pouco mais sobre a área de Robotica Educacional e se qualificarem para trabalharem de forma mais ativa os conteúdos escolares."
      }
    },
    {
      image: "escola.jpg",
      title: "Roboeduc na Escola",
      description: "Para Diretores escolares",
      content: {
        schedule: null,
        place: null,
        text: "Além das turmas que são ofertadas em nossa sede, também oferecemos nossos serviços para escolas da rede particular e pública de ensino. Entre em contato conosco para saber como nossa metodologia pode ser trabalhada em sua escola!"
      }
    },
    {
      image: "aprendizagem.jpg",
      title: "Objetos Virtuais de Aprendizagem",
      description: "Personalização de conteúdos para ESCOLA",
      content: {
        schedule: null,
        place: null,
        text: "Trabalhamos desenvolvendo Games, Histórias Interativas, Conteúdo em Realizada Virtual e Aumentada, Vídeos Educacionais e Conteúdo EAD para as ESCOLAS."
      }
    },
    {
      image: "educacional.jpg",
      title: "Consultoria em Tecnologias Educacionais",
      description: "Desenvolvimento e acompanhamento de projetos escolares",
      content: {
        schedule: null,
        place: null,
        text: "Consultoria na implantação, desenvolvimento e acompanhamento de projetos que envolvam novas tecnologias em educação para as ESCOLAS."
      }
    },
    {
      image: "sistema.jpg",
      title: "Sistema de Acompanhamento Académico",
      description: "Sistema Web/Mobile de acompanhamento",
      content: {
        schedule: null,
        place: null,
        text: "A Roboeduc conta com um sistema web que possibilita todo o acompamento das atividades realizadas pelos alunos, pais e Gestores da Escola. Ela provê ferramentas de acesso para os pais poderem acompanhar o desenvolvimento de seu filho nas aulas. A mesma ferramenta pode ser utilizada pelos diretores de escolas para melhor gerenciar a aulas, professores, presenças, emitir relatórios e canal de comunicação com os pais. "
      }
    },
    {
      image: "inbox.jpg",
      title: "Roboeduc Inbox",
      description: "EaD de Robótica Educacional",
      content: {
        schedule: null,
        place: null,
        text: "Primeiro EaD de Robótica Educacional do Brasil"
      }
    }
  ]
}

export const services = {
  PT_BR: PT_BR,
  PT_PT: PT_PT
}
